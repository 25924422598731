export const ASSETS_MULTI_SELECT_MORE_ITEMS = {"id":"assets_multi_select_more_items","translations":{"frontend+intl-icu":{"en":"{ items, plural,\n  =0 { There are no more items }\n  =1 { There is one more item }\n  other { There are # more items }\n}"}}};
export const ASSETS_MULTI_SELECT_TEXTS_EMPTY = {"id":"assets_multi_select_texts_empty","translations":{"frontend+intl-icu":{"en":"empty"}}};
export const ASSETS_MULTI_SELECT_TEXTS_EXCEED = {"id":"assets_multi_select_texts_exceed","translations":{"frontend+intl-icu":{"en":"number of tags exceeded"}}};
export const ASSETS_MULTI_SELECT_TEXTS_PATTERN = {"id":"assets_multi_select_texts_pattern","translations":{"frontend+intl-icu":{"en":"pattern mismatch"}}};
export const ASSETS_MULTI_SELECT_TEXTS_DUPLICATE = {"id":"assets_multi_select_texts_duplicate","translations":{"frontend+intl-icu":{"en":"already exists"}}};
export const ASSETS_MULTI_SELECT_TEXTS_NOT_ALLOWED = {"id":"assets_multi_select_texts_notAllowed","translations":{"frontend+intl-icu":{"en":"not allowed"}}};
export const DURATION_HOURS = {"id":"duration_hours","translations":{"frontend+intl-icu":{"en":"{hours, plural,\n    one   {One hour}\n    other {# hours}\n}"}}};
export const DURATION_MINUTES = {"id":"duration_minutes","translations":{"frontend+intl-icu":{"en":"{minutes, plural,\n    one   {One minute}\n    other {# minutes}\n}"}}};
export const DURATION_SECONDS = {"id":"duration_seconds","translations":{"frontend+intl-icu":{"en":"{seconds, plural,\n    one   {One second}\n    other {# seconds}\n}"}}};
export const AND = {"id":"and","translations":{"frontend+intl-icu":{"en":"and"}}};
export const PLACEHOLDER = {"id":"placeholder","translations":{"frontend+intl-icu":{"en":"Select a value"}}};
